import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../assets/scss/main.scss";
import "../../../assets/fontawesome/css/all.min.css";

import ContentLoadingIndicatorContainer from "../../../components/profile/shared/ContentLoadingIndicatorContainer";

export default () => {
    const { isLoading, user } = useAuth0();

    const redirectTo = (url) => {
        window.location.assign(url);
    };

    const redirectToPublicTrialPage = () => {
        redirectTo(`${window.location.origin}/trial`);
    };

    React.useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!user) {
            redirectToPublicTrialPage();
            return;
        }

        const allowedTenants = user['https://frendsapp.com/allowed-tenants'];
        if (!allowedTenants || !allowedTenants.length) {
            redirectToPublicTrialPage();
            return;
        }

        const redirectToTenantUrl = allowedTenants[0].tenant_URL;
        if (!redirectToTenantUrl) {
            redirectToPublicTrialPage();
            return;
        }

        redirectTo(`${redirectToTenantUrl}/productTours`);
    }, [isLoading, user]);

    return <ContentLoadingIndicatorContainer />;
};